import type { LandingPageDataResponse } from '@safc/api-client';
import { usePublicControllerGetLandingPageStats } from '@safc/api-client';
import { publicRoutes } from '@safc/ui-utils/routes';
import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { MouseEvent } from 'react';
import { useAppAuth } from '../../hooks/auth/useAppAuth';
import type { StatBoxProps } from './StatBox/StatBox';

export const useHomepageEffects = (isMobile: boolean) => {
  const { data, isLoading } = usePublicControllerGetLandingPageStats();
  const [scrolled, setScrolled] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const landingPageData = data ?? ({} as LandingPageDataResponse);
  const aboutRef = useRef<HTMLElement>(null);
  const documentationRef = useRef<HTMLElement>(null);
  const contactUsRef = useRef<HTMLElement>(null);

  const { login } = useAppAuth();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 64) {
        return setScrolled(true);
      }
      return setScrolled(false);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (currentRef: React.RefObject<HTMLElement>) => {
    if (currentRef.current) {
      if (isMobile) {
        setTimeout(() => {
          currentRef.current!.scrollIntoView({
            behavior: 'smooth',
          });
        }, 100);
      } else {
        currentRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpened((state) => !state);
  }, [setIsDrawerOpened]);

  const handleAboutClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleScroll(aboutRef);
  };

  const handleDocumentationClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleScroll(documentationRef);
  };

  const handleContactUsClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleScroll(contactUsRef);
  };

  const {
    supplyCountriesOfOrigin,
    uniqueSuppliers,
    fuelProviders,
    safCaVolume,
    co2Abated,
    logisticServiceProviders,
    airTransportProviders,
    aviationCustomers,
  } = landingPageData;

  const issuanceStatisticProps: StatBoxProps = {
    buttonText: 'Visit the issuance table',
    header: {
      stat: safCaVolume?.toLocaleString('en-US'),
      explainer: 'Metric tonnes of neat SAF issued',
    },
    stats: [
      {
        stat: fuelProviders?.toLocaleString('en-US'),
        explainer: 'Fuel Providers',
      },
      {
        stat: uniqueSuppliers?.toLocaleString('en-US'),
        explainer: 'Fuel producing facilities',
      },
      {
        stat: supplyCountriesOfOrigin?.toLocaleString('en-US'),
        explainer: 'Fuel producing countries',
      },
    ],
  };

  const retirementStatisticProps: StatBoxProps = {
    buttonText: 'See retired SAFc here',
    link: publicRoutes.RETIREMENT,
    header: {
      stat: co2Abated?.toLocaleString('en-US'),
      explainer: 'Metric tonnes of abated CO2e issued',
      explainerTooltip:
        'Emissions reductions are calculated as the cumulative difference between the lifecycle carbon intensity of SAF and conventional jet fuel.',
    },
    stats: [
      {
        stat: aviationCustomers?.toLocaleString('en-US'),
        explainer: 'Aviation customers',
      },
      {
        stat: airTransportProviders?.toLocaleString('en-US'),
        explainer: 'Air transport providers',
      },
      {
        stat: logisticServiceProviders?.toLocaleString('en-US'),
        explainer: 'Logistics service providers',
      },
    ],
  };

  return {
    retirementStatisticProps,
    issuanceStatisticProps,
    isLoading,
    aboutRef,
    documentationRef,
    handleAboutClick,
    handleDocumentationClick,
    login,
    scrolled,
    isDrawerOpened,
    toggleDrawer,
    contactUsRef,
    handleContactUsClick,
  };
};
